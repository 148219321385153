import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';

import license from '../../assets/img/license.jpg';
import sert from '../../assets/img/sert.jpg';
import directer from '../../assets/img/directer.jpg';
import { ICompany } from '../../types/types';
import { fetchCompany } from '../../http/companyAPI';

import './mainDescr.sass';


const MainDescr: React.FC = () => {
    const [show, setShow] = useState<boolean>(false);
    const [show1, setShow1] = useState<boolean>(false);
    const [company, setCompany] = useState<ICompany>({
        id: 1,
        description: "ООО «Инженерное бюро Цаммит» успешно выполняет в Москве и в российских регионах полный комплекс работ по проектированию инженерных систем зданий и сооружений, включая все проектные стадии. Коллектив квалифицированных специалистов нашей фирмы имеет большой опыт выполнения сложных инновационных проектов. Наши сотрудники постоянно совершенствуют свои знания, участвуя в современных разработках и используя новейшие технические решения. Начиная с 2005 года наши специалисты разработали более 100 проектов.",
        department: [
            {
                id: 1,
                companyId: 1,
                department: '...'
            },
        ]
    });

    useEffect(() => {
        fetchCompany(1).then(data => setCompany(data));
    }, []);


    return (
        <div className='description'>
            <div className="description__text">{company.description}</div>

            <h3 className="description__title">История</h3>
            <div className="description__text">
                Год основания ООО "Инженерное бюро Цаммит": 2005, Россия, Москва.<br/>
                Генеральный директор: Кобзев Андрей Андреевич
            </div>

            <div className="description__container">
                <img className="description__container_photo" src={directer} alt="directer" />
                <ul className="description__container_text">Андрей Кобзев имеет  опыт работы в индустриальном направлении:
                    <li>Руководитель направления проектирования логистических зданий группы компаний ПИК</li>
                    <li>Руководитель проектного бюро компании ПНК – крупнейшего девелопера складских и промышленных зданий в России</li>
                </ul>
            </div>

            <h3 className="description__title">Лицензирование деятельности</h3>
            <div className="description__text">Cвидетельство СРО</div>
            <img className="description__license" src={license} alt="license" onClick={() => setShow(prev => !prev)} />
            <div className="description__text">Cертификат соответствия</div>
            <img className="description__license" src={sert} alt="sertification" onClick={() => setShow1(prev => !prev)} />     

            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="xl"
                centered
            >
                <Modal.Body>
                    <img className="description__license_large" src={license} alt="license" />
                </Modal.Body>
            </Modal>
            <Modal
                show={show1}
                onHide={() => setShow1(false)}
                size="xl"
                centered
            >
                <Modal.Body>
                    <img className="description__license_large" src={sert} alt="license" />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default MainDescr;